@charset "UTF-8";
.artic {
  color: #2e2828; }
  .artic * {
    box-sizing: border-box; }
  .artic__container_l {
    max-width: 1370px;
    padding: 0 80px;
    margin: 0 auto; }
    @media screen and (max-width: 640px) {
      .artic__container_l .artic__container_m {
        padding: 0; } }
    @media screen and (max-width: 1024px) {
      .artic__container_l {
        padding: 0 40px; } }
    @media screen and (max-width: 640px) {
      .artic__container_l {
        padding: 0 20px; } }
    .artic__container_l .content-wysiwyg {
      max-width: 100%; }
  .artic__container_m {
    max-width: 1180px;
    padding: 0 80px;
    margin: 0 auto; }
    @media screen and (max-width: 1024px) {
      .artic__container_m {
        padding: 0 40px; } }
    @media screen and (max-width: 640px) {
      .artic__container_m {
        padding: 0 20px; } }
    .artic__container_m .content-wysiwyg {
      max-width: 100%; }
  .artic__container_s {
    max-width: 910px;
    padding: 0 80px;
    margin: 0 auto; }
    @media screen and (max-width: 1024px) {
      .artic__container_s {
        padding: 0 40px; } }
    @media screen and (max-width: 640px) {
      .artic__container_s {
        padding: 0 20px; } }
    .artic__container_s .content-wysiwyg {
      max-width: 100%; }
  .artic__row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -35px; }
    @media screen and (max-width: 1024px) {
      .artic__row {
        margin: 0 -10px; } }
  .artic__col-6 {
    flex: 0 0 50%;
    padding: 0 35px;
    max-width: 50%; }
    @media screen and (max-width: 1024px) {
      .artic__col-6 {
        padding: 0 10px; } }
  @media screen and (max-width: 1024px) {
    .artic__tab-col-12 {
      flex: 0 0 100%;
      max-width: 100%; }
      .artic__tab-col-12 + .artic__tab-col-12 {
        margin-top: 20px; } }
  @media screen and (max-width: 640px) {
    .artic__mob-col-12 {
      flex: 0 0 100%; }
      .artic__mob-col-12 + .artic__mob-col-12 {
        margin-top: 20px; } }
  .artic__title_translated-top {
    position: absolute;
    top: 0;
    left: 50%;
    width: 100%;
    max-width: 660px;
    padding: 0 30px;
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
    background: #fff;
    transform: translate(-50%, -50%); }
    @media screen and (max-width: 1024px) {
      .artic__title_translated-top {
        top: -.7em;
        max-width: 620px;
        transform: translate(-50%, 0); } }
    @media screen and (max-width: 640px) {
      .artic__title_translated-top {
        position: static;
        transform: none;
        margin-bottom: 30px;
        padding: 0;
        font-size: 14px; } }
  .artic__banner {
    min-height: 495px;
    margin-bottom: 65px; }
    @media screen and (max-width: 640px) {
      .artic__banner {
        min-height: auto;
        margin-bottom: 24px; } }
  .artic__note {
    margin-top: 65px;
    margin-bottom: 36px; }
    @media screen and (max-width: 640px) {
      .artic__note {
        margin-top: 24px;
        margin-bottom: 15px; } }
  .artic__content {
    margin-top: 20px;
    margin-bottom: 20px; }
    @media screen and (max-width: 640px) {
      .artic__content {
        margin-top: 15px;
        margin-bottom: 15px; } }
  .artic__pic-block {
    margin-top: 40px;
    margin-bottom: 40px; }
    @media screen and (max-width: 1024px) {
      .artic__pic-block {
        margin-top: 30px; } }
    @media screen and (max-width: 640px) {
      .artic__pic-block {
        margin-top: 20px;
        margin-bottom: 25px; } }
  .artic__pic-note {
    margin-top: 30px;
    margin-bottom: 50px; }
    @media screen and (max-width: 1024px) {
      .artic__pic-note {
        margin-top: 80px; } }
    @media screen and (max-width: 640px) {
      .artic__pic-note {
        margin-top: 25px;
        margin-bottom: 30px; } }
  .artic__grid-list {
    margin-top: 40px;
    margin-bottom: 30px; }
    @media screen and (max-width: 1024px) {
      .artic__grid-list {
        margin-bottom: 40px; } }
    @media screen and (max-width: 640px) {
      .artic__grid-list {
        margin-top: 13px;
        margin-bottom: 20px; } }
  .artic__list-heart {
    margin-top: 30px;
    margin-bottom: 65px; }
    @media screen and (max-width: 1024px) {
      .artic__list-heart {
        margin-top: 60px; } }
    @media screen and (max-width: 640px) {
      .artic__list-heart {
        margin-top: 30px;
        margin-bottom: 85px; } }
  .artic__pulse {
    margin-top: 108px; }
    @media screen and (max-width: 1024px) {
      .artic__pulse {
        margin-top: 55px; } }
    @media screen and (max-width: 640px) {
      .artic__pulse {
        margin-top: 45px; } }
  .artic__form {
    margin-top: 54px;
    margin-bottom: 44px; }
    @media screen and (max-width: 640px) {
      .artic__form {
        margin-top: 15px;
        margin-bottom: 15px; } }
  .artic__form * {
    box-sizing: border-box; }
  .artic__source {
    margin-top: 44px; }
    @media screen and (max-width: 640px) {
      .artic__source {
        margin-top: 15px; } }
  .artic__inner {
    position: relative; }
  .artic__top {
    position: absolute;
    bottom: -63px;
    right: 78px;
    z-index: 1;
    transition: opacity .4s;
    transform: translate(0, 0);
    transform: translate3d(0, 0, 0);
    will-change: position, transform; }
    .artic__top_hidden {
      opacity: 0; }
    @media screen and (max-width: 1024px) {
      .artic__top {
        display: none; } }
  .artic__title-2col {
    position: relative;
    padding-bottom: 70px;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    color: #103045;
    text-align: center;
    text-transform: uppercase; }
    @media screen and (max-width: 640px) {
      .artic__title-2col {
        padding-bottom: 0;
        font-size: 14px; } }
  .artic__title-2col::before, .artic__title-2col::after {
    content: "";
    position: absolute;
    bottom: 0;
    display: block;
    width: 64px;
    height: 64px;
    background: center/100% auto url("../img/title-2col.png") no-repeat; }
    @media screen and (max-width: 640px) {
      .artic__title-2col::before, .artic__title-2col::after {
        content: none; } }
  .artic__title-2col::before {
    left: 25%;
    margin-left: -32px;
    transform: scaleX(-1); }
  .artic__title-2col::after {
    right: 25%;
    margin-right: -32px; }

.artic-section {
  padding: 30px 0; }
  .artic-section .content-wysiwyg {
    padding-left: 0px;
    padding-right: 0px; }
  .artic-section .content-wysiwyg h3 {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
    padding-right: 0; }
  .artic-section_border-tb {
    padding-top: 60px; }
    @media screen and (max-width: 1024px) {
      .artic-section_border-tb {
        padding-top: 40px; } }
  .artic-section_border-tb__inner {
    position: relative;
    padding-top: 90px;
    padding-bottom: 90px;
    border: 1px solid #a7a7a7; }
    @media screen and (max-width: 1024px) {
      .artic-section_border-tb__inner {
        padding-bottom: 40px; } }
    @media screen and (max-width: 640px) {
      .artic-section_border-tb__inner {
        margin-top: 0;
        padding-top: 0;
        padding-bottom: 0;
        border: none; } }
  .artic-section_gray {
    background: #ebf5f9; }
    .artic-section_gray .artic__title_translated-top {
      background: #ebf5f9; }
    .artic-section_gray .artic__content {
      padding: 25px 55px 25px 55px; }
      @media screen and (max-width: 1024px) {
        .artic-section_gray .artic__content {
          padding-left: 40px;
          padding-right: 40px; } }
      @media screen and (max-width: 640px) {
        .artic-section_gray .artic__content {
          padding-left: 20px;
          padding-right: 20px; } }
  @media screen and (max-width: 640px) {
    .artic-section__title-2col {
      padding-bottom: 0; } }

.banner {
  position: relative;
  padding-left: 82px;
  padding-top: 85px; }
  @media screen and (max-width: 1024px) {
    .banner {
      padding-left: 39px;
      padding-top: 52px; } }
  @media screen and (max-width: 640px) {
    .banner {
      padding-left: 0;
      padding-top: 0; } }
  .banner__pic {
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 495px; }
    @media screen and (max-width: 1024px) {
      .banner__pic {
        height: 424px; } }
    @media screen and (max-width: 640px) {
      .banner__pic {
        position: static;
        height: 160px; } }
  .banner__img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: right top;
    font-family: 'object-fit: cover; object-position: right top;'; }
    @media screen and (max-width: 1200px) {
      .banner__img {
        object-fit: cover;
        object-position: center top;
        font-family: 'object-fit: cover; object-position: center top;'; } }
    @media screen and (max-width: 1024px) {
      .banner__img {
        object-fit: cover;
        object-position: right top;
        font-family: 'object-fit: cover; object-position: right top;'; } }
    @media screen and (max-width: 640px) {
      .banner__img {
        object-fit: cover;
        object-position: right center;
        font-family: 'object-fit: cover; object-position: right center;'; } }
  .banner__inner {
    display: flex;
    flex-direction: column; }

@media screen and (max-width: 1300px) and (min-width: 1025px) {
  .banner__inner {
    width: 50%; } }
  .banner__hgroup {
    width: 700px; }
    @media screen and (max-width: 1024px) {
      .banner__hgroup {
        width: 430px; } }
    @media screen and (max-width: 640px) {
      .banner__hgroup {
        position: absolute;
        top: 0;
        width: 60%;
        padding-left: 20px;
        padding-top: 10px; } }
  .banner__title {
    position: relative;
    z-index: 1;
    max-width: 100%;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 48px;
    font-weight: 400;
    line-height: 57px; }
    @media screen and (max-width: 1300px) {
      .banner__title {
        width: 100%; } }
    @media screen and (max-width: 640px) {
      .banner__title {
        font-size: 19px;
        font-weight: 400;
        line-height: 23.58px;
        letter-spacing: 0.53px; } }
  .banner__subtitle {
    position: relative;
    max-width: 100%;
    font-size: 24px;
    line-height: 1.5;
    z-index: 1;
    margin-top: 10px; }
    @media screen and (max-width: 640px) {
      .banner__subtitle {
        font-size: 14px;
        line-height: 1.2;
        margin-top: 3px; } }
  .banner__descr {
    position: relative;
    z-index: 1;
    width: 577px;
    margin-top: 84px;
    padding-left: 57px;
    padding-top: 53px;
    padding-right: 29px;
    padding-bottom: 56px;
    color: rgba(255, 255, 255, 0.8);
    font-size: 18px;
    font-weight: 400;
    line-height: 31px;
    background-color: #e82a2a; }
    @media screen and (max-width: 1300px) {
      .banner__descr {
        width: 100%; } }
    @media screen and (max-width: 1024px) {
      .banner__descr {
        width: 521px;
        margin-top: 52px; } }
    @media screen and (max-width: 640px) {
      .banner__descr {
        width: 100%;
        margin-top: 0;
        padding-left: 22px;
        padding-top: 28px;
        padding-right: 22px;
        padding-bottom: 28px;
        font-size: 14px;
        font-weight: 400;
        line-height: 18.15px;
        letter-spacing: 0.28px; } }
  .banner__descr-title {
    margin-top: -20px;
    margin-bottom: 20px;
    font-size: 38px;
    font-weight: bold;
    line-height: 1.2;
    letter-spacing: 0.6px;
    color: #fff; }
    @media screen and (max-width: 640px) {
      .banner__descr-title {
        margin-top: 0;
        font-size: 24px;
        text-align: center; } }
  @media screen and (min-width: 1025px) {
    .banner_special {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding-left: 0;
      padding-right: 82px; } }

.note {
  position: relative;
  width: auto;
  padding-left: 140px;
  padding-right: 140px;
  margin-left: auto;
  margin-right: auto;
  max-width: 860px;
  padding-top: 42px;
  padding-bottom: 43px;
  font-size: 24px;
  font-style: italic;
  line-height: 34px;
  text-align: center;
  background-color: #fff;
  border: 2px solid #e82a2a; }
  .note::before, .note::after {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    width: calc(50vw - 50% - 10px);
    height: 111px;
    background-image: url("../img/pulse_bg.png");
    background-repeat: repeat-x;
    background-position: center;
    content: ''; }
    @media screen and (max-width: 1023px) {
      .note::before, .note::after {
        width: calc(50vw - 50%); } }
    @media screen and (max-width: 640px) {
      .note::before, .note::after {
        height: 32px;
        background-image: url("../img/pulse_bg_mobile.png"); } }
  .note::before {
    right: 100%;
    background-position: 2%; }
  .note::after {
    left: 100%;
    background-position: 11%; }
  @media screen and (max-width: 1024px) {
    .note {
      max-width: 549px;
      padding-top: 45px;
      padding-right: 45px;
      padding-bottom: 45px;
      padding-left: 45px; } }
  @media screen and (max-width: 640px) {
    .note {
      width: auto;
      padding-left: 12px;
      padding-right: 12px;
      margin-left: 36px;
      margin-right: 36px;
      padding-top: 20px;
      padding-bottom: 20px;
      font-size: 14px;
      line-height: 18.41px;
      letter-spacing: 0.27px; } }
  .note sup {
    color: #e82a2a; }

.content-wysiwyg {
  width: 860px;
  padding-left: 55px;
  padding-right: 55px;
  margin-left: auto;
  margin-right: auto;
  max-width: 860px;
  width: auto;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px; }
  .content-wysiwyg .construct-big__title {
    text-align: left; }
  @media screen and (max-width: 1024px) {
    .content-wysiwyg {
      width: auto;
      padding-left: 40px;
      padding-right: 40px;
      margin-left: auto;
      margin-right: auto;
      max-width: none; }
      .content-wysiwyg .construct-big__title {
        padding-left: 40px; } }
  @media screen and (max-width: 640px) {
    .content-wysiwyg {
      width: auto;
      padding-left: 20px;
      padding-right: 20px;
      margin-left: auto;
      margin-right: auto;
      font-size: 14px;
      font-weight: 400;
      line-height: 19.13px;
      letter-spacing: 0.26px; }
      .content-wysiwyg .construct-big__title {
        padding-left: 0;
        text-align: center;
        margin-right: 20px;
        font-size: 24px;
        line-height: 26px; } }
  .content-wysiwyg sup {
    color: #e82a2a; }
  .content-wysiwyg .h3-title,
  .content-wysiwyg h3 {
    margin-top: 40px;
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 700;
    line-height: 27px;
    letter-spacing: 0.64px; }
    @media screen and (max-width: 640px) {
      .content-wysiwyg .h3-title,
      .content-wysiwyg h3 {
        margin-top: 25px;
        margin-bottom: 15px;
        font-size: 14px;
        font-weight: 700;
        line-height: 20.54px;
        letter-spacing: 0.25px; } }
  .content-wysiwyg p + h3,
  .content-wysiwyg ul + h3,
  .content-wysiwyg ol + h3,
  .content-wysiwyg img + h3 {
    margin-top: 55px; }
    @media screen and (max-width: 640px) {
      .content-wysiwyg p + h3,
      .content-wysiwyg ul + h3,
      .content-wysiwyg ol + h3,
      .content-wysiwyg img + h3 {
        margin-top: 25px; } }
  .content-wysiwyg p,
  .content-wysiwyg ul,
  .content-wysiwyg ol {
    margin-top: 10px;
    margin-bottom: 10px; }
  @media screen and (max-width: 1024px) {
    .content-wysiwyg ul,
    .content-wysiwyg ol,
    .content-wysiwyg h3 {
      padding-left: 40px;
      padding-right: 20px; } }
  @media screen and (max-width: 640px) {
    .content-wysiwyg ul,
    .content-wysiwyg ol,
    .content-wysiwyg h3 {
      padding-left: 20px; } }
  .content-wysiwyg ul li::before {
    position: absolute;
    left: -51px;
    display: block;
    font-size: 30px;
    color: #e82a2a;
    content: '—'; }
    @media screen and (max-width: 640px) {
      .content-wysiwyg ul li::before {
        left: -20px;
        font-size: 15px;
        font-weight: 900; } }
  .content-wysiwyg ol {
    counter-reset: item; }
    .content-wysiwyg ol li .list-item {
      position: absolute;
      left: -35px;
      display: block;
      font-weight: 700;
      color: #e82a2a;
      content: counter(item) ".";
      counter-increment: item; }
      @media screen and (max-width: 640px) {
        .content-wysiwyg ol li .list-item {
          left: -18px; } }
    @media screen and (max-width: 640px) {
      .content-wysiwyg ol.list-circle {
        padding-left: 35px; } }
    .content-wysiwyg ol.list-circle li .list-item {
      position: absolute;
      left: -52px;
      top: -6px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 39px;
      height: 39px;
      color: #1778b6;
      font-weight: 300;
      font-size: 14px;
      border: 1px solid #e82a2a;
      border-radius: 50%; }
      @media screen and (max-width: 1024px) {
        .content-wysiwyg ol.list-circle li .list-item {
          left: -42px;
          top: -1px;
          width: 30px;
          height: 30px; } }
      @media screen and (max-width: 640px) {
        .content-wysiwyg ol.list-circle li .list-item {
          left: -34px;
          top: -4px;
          width: 25px;
          height: 25px; } }
  .content-wysiwyg li {
    position: relative; }
    .content-wysiwyg li + li {
      margin-top: 22px; }
      @media screen and (max-width: 640px) {
        .content-wysiwyg li + li {
          margin-top: 15px; } }
  .content-wysiwyg img {
    display: block;
    margin-top: 35px;
    margin-bottom: 35px; }
    @media screen and (max-width: 1024px) {
      .content-wysiwyg img {
        margin-top: 50px;
        margin-bottom: 60px;
        margin-left: -40px;
        width: 100vw; } }
    @media screen and (max-width: 640px) {
      .content-wysiwyg img {
        margin-top: 30px;
        margin-bottom: 22px;
        margin-left: -20px; } }

.pic-block {
  display: flex;
  align-items: center;
  padding-left: 120px;
  padding-right: 150px;
  padding-top: 42px;
  padding-bottom: 50px;
  color: #000;
  background-color: rgba(226, 238, 244, 0.6); }
  @media screen and (max-width: 1024px) {
    .pic-block {
      flex-direction: column;
      overflow: hidden;
      padding-top: 0;
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;
      background-color: inherit; } }
  @media screen and (max-width: 1024px) {
    .pic-block__pic-wrap {
      position: relative;
      padding-bottom: 35px; }
      .pic-block__pic-wrap::after {
        position: absolute;
        z-index: -1;
        left: -100vw;
        bottom: 0;
        width: 200vw;
        height: calc(50% + 18px);
        background: rgba(226, 238, 244, 0.6);
        content: ''; } }
  @media screen and (max-width: 640px) {
    .pic-block__pic-wrap {
      max-width: 50%;
      padding-bottom: 20px; }
      .pic-block__pic-wrap::after {
        height: calc(50% + 10px); } }
  .pic-block__pic {
    max-width: 360px; }
    @media screen and (max-width: 1024px) {
      .pic-block__pic {
        max-width: 100%;
        object-fit: contain;
        font-family: 'object-fit: contain;'; } }
  .pic-block__content {
    width: 100%;
    padding-left: 87px;
    padding-right: 7px;
    letter-spacing: 0.1px; }
    @media screen and (max-width: 1024px) {
      .pic-block__content {
        padding-bottom: 80px;
        background-color: rgba(226, 238, 244, 0.6); }
        .pic-block__content ul + p {
          padding-left: 40px; } }
    @media screen and (max-width: 640px) {
      .pic-block__content {
        padding-right: 0;
        padding-bottom: 30px;
        padding-left: 20px; }
        .pic-block__content ul + p {
          padding-left: 20px; } }
  @media screen and (min-width: 1025px) {
    .pic-block_special {
      flex-direction: row-reverse;
      padding-left: 150px;
      padding-right: 120px; } }

.pic-block-new__content {
  display: flex;
  color: #2e2828; }
  @media screen and (max-width: 1024px) {
    .pic-block-new__content {
      flex-direction: column; } }
  @media screen and (max-width: 640px) {
    .pic-block-new__content {
      padding: 25px 0; } }

.pic-block-new__img-wrap {
  flex: 0 0 50%; }

.pic-block-new__img {
  display: block;
  max-width: 100%;
  margin: 0 auto; }

.pic-block-new__desc-wrap {
  flex: 0 0 45%;
  margin-left: 5%; }
  @media screen and (max-width: 1024px) {
    .pic-block-new__desc-wrap {
      margin-top: 30px;
      margin-left: 0; } }
  @media screen and (max-width: 640px) {
    .pic-block-new__desc-wrap {
      margin-top: 0; } }

.pic-block-new__desc-title {
  margin-top: 18px;
  margin-bottom: 46px;
  font-size: 38px;
  line-height: 1.1; }
  @media screen and (max-width: 640px) {
    .pic-block-new__desc-title {
      margin-bottom: 30px;
      font-size: 24px; } }

.pic-note {
  position: relative;
  display: flex;
  max-width: 1210px;
  margin-left: auto;
  margin-right: auto; }
  @media screen and (max-width: 1024px) {
    .pic-note {
      flex-direction: column; } }
  .pic-note__pic-wrap {
    width: 780px;
    padding-top: 58px; }
    @media screen and (max-width: 1024px) {
      .pic-note__pic-wrap {
        width: 100%;
        padding-top: 0; } }
  .pic-note__pic {
    display: block;
    width: 100%; }
    @media screen and (max-width: 1024px) {
      .pic-note__pic {
        height: 100%;
        transform: none; } }
  .pic-note__img {
    max-width: 780px; }
    @media screen and (max-width: 1024px) {
      .pic-note__img {
        display: block;
        width: 100%;
        max-width: none;
        object-fit: cover;
        font-family: 'object-fit: cover;'; } }
  .pic-note__content {
    z-index: 1;
    top: 0;
    right: 0;
    width: 608px;
    margin-left: -178px;
    color: #fff; }
    @media screen and (max-width: 1210px) {
      .pic-note__content {
        margin-left: calc(50vw - 780px); } }
    @media screen and (max-width: 1024px) {
      .pic-note__content {
        width: 100%;
        margin-left: 0; } }
  .pic-note__inner {
    padding-left: 48px;
    padding-top: 55px;
    padding-right: 48px;
    padding-bottom: 70px;
    background-color: #e82a2a; }
    @media screen and (max-width: 1024px) {
      .pic-note__inner {
        padding-left: 40px;
        padding-top: 65px;
        padding-right: 60px;
        padding-bottom: 60px; } }
    @media screen and (max-width: 640px) {
      .pic-note__inner {
        padding-left: 20px;
        padding-top: 30px;
        padding-right: 20px;
        padding-bottom: 30px; } }
  .pic-note__title {
    margin-bottom: 37px;
    font-size: 38px;
    font-weight: 700;
    line-height: 45px;
    letter-spacing: 0.76px; }
    @media screen and (max-width: 1024px) {
      .pic-note__title {
        max-width: 80%;
        margin-bottom: 35px; } }
    @media screen and (max-width: 640px) {
      .pic-note__title {
        font-size: 23px;
        font-weight: 700;
        line-height: 26.97px; } }
  .pic-note__text {
    opacity: 0.7;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.54px; }
    @media screen and (max-width: 640px) {
      .pic-note__text {
        font-size: 14px;
        font-weight: 400;
        line-height: 17.98px;
        letter-spacing: 0.28px; } }
  @media screen and (min-width: 1025px) {
    .pic-note_special {
      flex-direction: row-reverse; }
      .pic-note_special .pic-note__content {
        margin-left: 0;
        margin-right: -178px; } }
    @media screen and (min-width: 1025px) and (max-width: 1210px) {
      .pic-note_special .pic-note__content {
        margin-right: calc(50vw - 780px); } }

.grid-list {
  width: 860px;
  padding-left: 55px;
  padding-right: 55px;
  margin-left: auto;
  margin-right: auto; }
  @media screen and (max-width: 1024px) {
    .grid-list {
      width: auto;
      padding-left: 20px;
      padding-right: 20px;
      margin-left: auto;
      margin-right: auto; } }
  @media screen and (max-width: 640px) {
    .grid-list {
      width: auto;
      padding-left: 10px;
      padding-right: 10px;
      margin-left: auto;
      margin-right: auto; } }
  .grid-list__row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
    margin-top: 50px; }
    @media screen and (max-width: 640px) {
      .grid-list__row {
        flex-direction: column;
        margin-top: 0;
        margin-bottom: 0; } }
  .grid-list__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    flex-basis: 50%;
    padding-left: 30px;
    padding-right: 30px;
    margin-left: auto;
    margin-right: auto; }
    @media screen and (max-width: 1024px) {
      .grid-list__item {
        padding-left: 20px;
        padding-right: 20px; } }
    @media screen and (max-width: 640px) {
      .grid-list__item {
        margin-top: 12px;
        margin-bottom: 12px;
        padding-left: 0;
        padding-right: 0; } }
  .grid-list__icon {
    display: block;
    height: 80px;
    width: 140px;
    margin-bottom: 10px;
    background-position: center;
    background-repeat: no-repeat; }
  .grid-list__text {
    width: 100%;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    text-align: center; }
    @media screen and (max-width: 640px) {
      .grid-list__text {
        font-size: 14px;
        line-height: 18.24px;
        letter-spacing: 0.25px; } }

.list-heart {
  counter-reset: clone;
  width: 860px;
  padding-left: 55px;
  padding-right: 55px;
  margin-left: auto;
  margin-right: auto; }
  @media screen and (max-width: 1024px) {
    .list-heart {
      width: auto;
      padding-left: 40px;
      padding-right: 40px;
      margin-left: auto;
      margin-right: auto; } }
  @media screen and (max-width: 640px) {
    .list-heart {
      width: auto;
      padding-left: 10px;
      padding-right: 10px;
      margin-left: auto;
      margin-right: auto; } }
  .list-heart__title {
    margin-bottom: 35px;
    color: #e82a2a;
    font-size: 38px;
    font-weight: 700;
    line-height: 42px;
    letter-spacing: 1.14px; }
    @media screen and (max-width: 640px) {
      .list-heart__title {
        margin-bottom: 15px;
        text-align: center;
        font-size: 24px;
        font-weight: 700;
        line-height: 28.71px;
        letter-spacing: 0; } }
  .list-heart__list {
    counter-reset: item; }
    @media screen and (max-width: 1024px) {
      .list-heart__list {
        padding-left: 85px; } }
    @media screen and (max-width: 640px) {
      .list-heart__list {
        padding-left: 30px;
        padding-right: 30px; } }
  .list-heart__item {
    position: relative;
    margin-top: 25px;
    margin-bottom: 25px;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px; }
    @media screen and (max-width: 1024px) {
      .list-heart__item {
        margin-top: 50px;
        margin-bottom: 50px; } }
    @media screen and (max-width: 640px) {
      .list-heart__item {
        width: 100%;
        margin-top: 0;
        margin-bottom: 0;
        padding-top: 45px;
        font-size: 14px;
        font-weight: 400;
        line-height: 19.14px;
        text-align: center; } }
    .list-heart__item .list-item {
      position: absolute;
      left: -100px;
      top: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 54px;
      height: 49px;
      background-image: url("../img/icon_heart.svg");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      color: #1778b6;
      font-size: 18px;
      font-weight: 400;
      line-height: 30px; }
      @media screen and (max-width: 1024px) {
        .list-heart__item .list-item {
          left: -85px; } }
      @media screen and (max-width: 640px) {
        .list-heart__item .list-item {
          left: 50%;
          top: 0px;
          width: 40px;
          height: 35px;
          font-size: 15px;
          line-height: 15.95px;
          transform: translate(-50%); } }
    .list-heart__item:not(.slick-cloned)::before {
      counter-increment: item; }
  .list-heart .slick-arrow {
    position: absolute;
    top: 50%; }
    .list-heart .slick-arrow::after {
      position: absolute;
      top: 7px;
      left: 5px;
      width: 16px;
      height: 16px;
      border-top: 2px solid red;
      border-right: 2px solid red;
      transform: rotate(-135deg);
      content: ''; }
    .list-heart .slick-arrow.slick-prev {
      left: -7px; }
    .list-heart .slick-arrow.slick-next {
      right: -7px; }
      .list-heart .slick-arrow.slick-next::after {
        left: -5px;
        transform: rotate(45deg); }
  .list-heart .slick-dots {
    left: 0;
    bottom: -35px; }
    .list-heart .slick-dots li {
      width: 10px;
      height: 10px;
      margin-left: 2px;
      margin-right: 2px;
      border: 2px solid #e82a2a;
      border-radius: 50%; }
      .list-heart .slick-dots li.slick-active {
        background-color: #e82a2a; }
      .list-heart .slick-dots li button {
        display: none; }

.list-icon {
  width: 860px;
  padding-left: 55px;
  padding-right: 55px;
  margin-left: auto;
  margin-right: auto; }
  @media screen and (max-width: 1024px) {
    .list-icon {
      width: auto;
      padding-left: 40px;
      padding-right: 40px;
      margin-left: auto;
      margin-right: auto; } }
  @media screen and (max-width: 640px) {
    .list-icon {
      width: auto;
      padding-left: 20px;
      padding-right: 20px;
      margin-left: auto;
      margin-right: auto; } }
  .list-icon__title {
    margin-top: 40px;
    margin-bottom: 25px;
    font-size: 16px;
    font-weight: 700;
    line-height: 27px;
    letter-spacing: 0.64px; }
    @media screen and (max-width: 1024px) {
      .list-icon__title {
        padding-left: 40px; } }
    @media screen and (max-width: 640px) {
      .list-icon__title {
        padding-left: 20px;
        font-size: 14px;
        font-weight: 700;
        line-height: 20.51px;
        letter-spacing: 0.25px; } }
    .list-icon__title sup {
      color: #2e2828; }
  @media screen and (max-width: 1024px) {
    .list-icon__list {
      padding-left: 40px; } }
  .list-icon__item {
    position: relative;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px; }
    .list-icon__item + .list-icon__item {
      margin-top: 20px; }
    @media screen and (max-width: 640px) {
      .list-icon__item {
        font-size: 14px;
        font-weight: 400;
        line-height: 20.51px;
        letter-spacing: 0.25px; } }
  .list-icon__img-wrap {
    position: absolute;
    left: -57px;
    top: -5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 39px;
    height: 39px; }
    @media screen and (max-width: 640px) {
      .list-icon__img-wrap {
        left: -46px;
        top: -14px; } }
  .list-icon__img {
    display: block;
    max-width: 100%; }

.heart-pulse {
  width: 100%;
  height: 297px;
  background-image: url("../img/pulse_bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #e11478;
  background-position: right; }
  @media screen and (max-width: 1024px) {
    .heart-pulse {
      background-position: calc(100% + 25px) top; } }
  @media screen and (max-width: 640px) {
    .heart-pulse {
      height: 190px;
      background-image: url("../img/pulse_bg_mobile.jpg");
      background-position: center right; } }
  .heart-pulse__inner {
    width: 860px;
    padding-left: 53px;
    padding-right: 53px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 50px;
    padding-bottom: 50px; }
    @media screen and (max-width: 1024px) {
      .heart-pulse__inner {
        width: auto;
        padding-left: 40px;
        padding-right: 40px;
        margin-left: auto;
        margin-right: auto; } }
    @media screen and (max-width: 640px) {
      .heart-pulse__inner {
        width: auto;
        padding-left: 20px;
        padding-right: 20px;
        margin-left: auto;
        margin-right: auto;
        padding-top: 22px;
        padding-bottom: 15px; } }
  .heart-pulse__title {
    margin-bottom: 20px;
    color: #fff;
    font-size: 32px;
    font-weight: 700;
    line-height: 38px; }
    @media screen and (max-width: 640px) {
      .heart-pulse__title {
        margin-bottom: 10px;
        font-size: 18px;
        font-weight: 700;
        line-height: 20.04px; } }
  .heart-pulse__text {
    width: 320px;
    margin-bottom: 45px;
    color: #fff;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px; }
    @media screen and (max-width: 640px) {
      .heart-pulse__text {
        width: 160px;
        margin-bottom: 23px;
        font-size: 14px;
        font-weight: 400;
        line-height: 18.04px; } }
  .heart-pulse__btn {
    display: inline-block;
    height: 46px;
    padding-left: 55px;
    padding-right: 55px;
    background-color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 46px;
    color: #e11478;
    transition: opacity .4s; }
    .heart-pulse__btn:hover, .heart-pulse__btn:focus {
      opacity: .75; }

.interest {
  width: 100%;
  padding-top: 57px;
  padding-bottom: 65px;
  background-color: #eef5f8; }
  .interest * {
    box-sizing: border-box; }
  .interest__title {
    margin-bottom: 49px;
    color: #000;
    font-size: 32px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: -0.5px;
    text-align: center; }
    @media screen and (max-width: 640px) {
      .interest__title {
        font-weight: 600;
        margin-bottom: 20px;
        font-size: 18px;
        line-height: 18.72px; } }
  .interest__inner {
    width: auto;
    padding-left: 0;
    padding-right: 0;
    margin-left: 4.5%;
    margin-right: 4.5%; }
  .interest__list {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    margin-left: auto;
    margin-right: auto;
    max-width: 1174px;
    display: flex;
    justify-content: center;
    margin-bottom: 0; }
  .interest .slick-arrow {
    position: absolute;
    top: 50%;
    background: none !important;
    transform: translateY(-50%); }
    .interest .slick-arrow::after {
      position: absolute;
      top: 7px;
      left: 5px;
      width: 16px;
      height: 16px;
      border-top: 2px solid red;
      border-right: 2px solid red;
      transform: rotate(-135deg);
      content: ''; }
    .interest .slick-arrow.slick-prev {
      left: 2px; }
    .interest .slick-arrow.slick-next {
      right: 2px; }
      .interest .slick-arrow.slick-next::after {
        left: -5px;
        transform: rotate(45deg); }

.preview {
  line-height: 22px;
  color: #000; }
  .preview__img {
    display: block;
    width: 100%;
    height: 159px;
    object-fit: cover;
    object-position: center top;
    font-family: 'object-fit: cover; object-position: center top;'; }
  .preview__wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 270px;
    padding-left: 22px;
    padding-top: 19px;
    padding-right: 22px;
    padding-bottom: 27px;
    background-color: #fff; }
    @media screen and (max-width: 640px) {
      .preview__wrap {
        height: 220px;
        padding-left: 15px;
        padding-top: 15px;
        padding-right: 15px;
        padding-bottom: 15px; } }
  .preview__content {
    position: relative;
    height: calc(100% - 30px); }
    @media screen and (max-width: 640px) {
      .preview__content {
        height: calc(100% - 19px);
        overflow: hidden; } }
    .preview__content::after {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 55px;
      background-image: linear-gradient(to top, #fff 0%, rgba(255, 255, 255, 0.7) 50%, rgba(255, 255, 255, 0) 80%, rgba(255, 255, 255, 0) 100%); }
      @media screen and (max-width: 640px) {
        .preview__content::after {
          content: ''; } }
  .preview__title {
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 700; }
    @media screen and (max-width: 640px) {
      .preview__title {
        margin-bottom: 12px;
        font-size: 16px; } }
  .preview__text {
    font-size: 14px;
    font-weight: 400; }
    @media screen and (max-width: 640px) {
      .preview__text {
        line-height: 14px; } }
  .preview__link {
    padding-top: 8px;
    color: #1778b6;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-transform: uppercase;
    letter-spacing: 1.96px;
    transition: color .2s; }
    .preview__link:hover, .preview__link:focus {
      color: #e82a2a; }
    @media screen and (max-width: 640px) {
      .preview__link {
        font-size: 9px;
        font-weight: 400;
        line-height: 11.13px;
        letter-spacing: 2.52px; } }

.form-subscribe {
  width: auto;
  padding-left: 0;
  padding-right: 0;
  margin-left: 4.5%;
  margin-right: 4.5%; }
  @media screen and (max-width: 1024px) {
    .form-subscribe {
      width: auto;
      padding-left: 0;
      padding-right: 0;
      margin-left: 110px;
      margin-right: 110px; } }
  @media screen and (max-width: 640px) {
    .form-subscribe {
      width: auto;
      padding-left: 20px;
      padding-right: 20px;
      margin-left: auto;
      margin-right: auto; } }
  .form-subscribe__title {
    margin-bottom: 40px;
    color: #000;
    font-size: 32px;
    font-weight: 700;
    line-height: 39.51px;
    text-align: center; }
    @media screen and (max-width: 1024px) {
      .form-subscribe__title {
        margin-bottom: 25px; } }
    @media screen and (max-width: 640px) {
      .form-subscribe__title {
        font-size: 18px;
        line-height: 18.58px; } }
  .form-subscribe__row {
    display: flex;
    justify-content: center; }
    @media screen and (max-width: 1024px) {
      .form-subscribe__row {
        flex-direction: column;
        align-items: center; } }
  .form-subscribe__label {
    position: relative;
    display: flex;
    align-items: center; }
    @media screen and (max-width: 1024px) {
      .form-subscribe__label + .form-subscribe__label {
        margin-top: 10px; } }
    @media screen and (max-width: 640px) {
      .form-subscribe__label + .form-subscribe__label {
        margin-top: 7px; } }
    .form-subscribe__label + .form-subscribe__label .form-subscribe__input {
      margin-left: 21px; }
      @media screen and (max-width: 1024px) {
        .form-subscribe__label + .form-subscribe__label .form-subscribe__input {
          margin-left: 0; } }
      @media screen and (max-width: 640px) {
        .form-subscribe__label + .form-subscribe__label .form-subscribe__input {
          width: 100%; } }
    @media screen and (max-width: 640px) {
      .form-subscribe__label {
        align-items: start;
        width: 100%; } }
  .form-subscribe__input {
    width: 310px;
    height: 46px;
    padding-left: 20px;
    padding-right: 20px;
    border: 1px solid #1778b6;
    color: #000;
    font-family: 'Gotham';
    font-size: 14px;
    font-weight: 400;
    line-height: 23px; }
    .form-subscribe__input::placeholder {
      color: rgba(0, 0, 0, 0.5); }
    @media screen and (max-width: 640px) {
      .form-subscribe__input {
        width: 100%;
        height: 31px;
        padding-left: 11px;
        padding-right: 11px;
        line-height: 11.55px; } }
  .form-subscribe__submit {
    height: 46px;
    padding-left: 50px;
    padding-right: 50px;
    background-color: #1778b6;
    border: none;
    color: #fff;
    font-family: 'Gotham';
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    transition: opacity .2s; }
    .form-subscribe__submit:hover, .form-subscribe__submit:focus {
      opacity: .75; }
    @media screen and (max-width: 1024px) {
      .form-subscribe__submit {
        width: 312px;
        margin-top: 28px; } }
    @media screen and (max-width: 640px) {
      .form-subscribe__submit {
        width: 100%;
        height: 31px;
        margin-top: 11px;
        font-size: 14px;
        line-height: 14.06px; } }
  .form-subscribe__footer {
    display: flex;
    justify-content: space-between;
    margin-top: 43px;
    max-width: 1227px; }
    @media screen and (max-width: 1024px) {
      .form-subscribe__footer {
        flex-direction: column; } }
    @media screen and (max-width: 640px) {
      .form-subscribe__footer {
        margin-top: 20px; } }
  .form-subscribe__check.jq-checkbox {
    width: 24px;
    height: 24px;
    border-color: #1778b6;
    background-color: #fff;
    background-image: none; }
    @media screen and (max-width: 1024px) {
      .form-subscribe__check.jq-checkbox {
        flex: 1 0 24px; } }
    @media screen and (max-width: 640px) {
      .form-subscribe__check.jq-checkbox {
        flex: 1 0 13px;
        width: 13px;
        height: 13px;
        margin-top: 3px; } }
  .form-subscribe__check-text {
    color: #000;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: -0.1px; }
    @media screen and (max-width: 640px) {
      .form-subscribe__check-text {
        line-height: 16px; } }
  .form-subscribe__link {
    color: #1778b6;
    text-decoration: underline; }
    .form-subscribe__link:hover, .form-subscribe__link:focus {
      text-decoration: none; }
  .form-subscribe__note {
    padding-left: 20px;
    color: rgba(0, 0, 0, 0.5);
    font-size: 14px;
    font-weight: 400;
    line-height: 23px;
    white-space: nowrap; }
    @media screen and (max-width: 1024px) {
      .form-subscribe__note {
        margin-top: 10px;
        padding-left: 0; } }
    @media screen and (max-width: 640px) {
      .form-subscribe__note {
        margin-left: 23px; } }

.source-block {
  width: auto;
  padding-left: 0;
  padding-right: 0;
  margin-left: 4.5%;
  margin-right: 4.5%;
  padding-top: 15px;
  padding-bottom: 25px;
  border-top: 1px solid rgba(0, 0, 0, 0.3); }
  .source-block * {
    box-sizing: border-box; }
  @media screen and (max-width: 640px) {
    .source-block {
      padding-bottom: 20px; } }
  .source-block__title {
    position: relative;
    margin-bottom: 10px;
    color: #2e2828;
    font-size: 16px;
    font-weight: 700;
    line-height: 31px; }
    .source-block__title:hover {
      cursor: pointer; }
    .source-block__title::after {
      position: absolute;
      top: 8px;
      right: 3px;
      width: 10px;
      height: 10px;
      border-left: 2px solid #000;
      border-top: 2px solid #000;
      transform: rotate(-135deg);
      transition: top .3s, transform .3s;
      content: ''; }
      @media screen and (max-width: 640px) {
        .source-block__title::after {
          top: 0; } }
    @media screen and (max-width: 640px) {
      .source-block__title {
        font-size: 18px;
        line-height: 15.6px; } }
  .source-block__content {
    display: none; }
  .source-block__list {
    margin-bottom: 45px;
    padding-left: 24px;
    color: #2e2828;
    font-size: 13px;
    font-weight: 400;
    line-height: 21px;
    counter-reset: item; }
    @media screen and (max-width: 640px) {
      .source-block__list {
        margin-bottom: 20px;
        padding-left: 17px;
        font-size: 14px;
        line-height: 16.1px;
        letter-spacing: 0.28px; } }
  .source-block__item {
    position: relative;
    word-wrap: break-word;
    overflow-wrap: break-word; }
    .source-block__item::before {
      position: absolute;
      left: -22px;
      content: counter(item) ".";
      counter-increment: item; }
      @media screen and (max-width: 640px) {
        .source-block__item::before {
          left: -15px; } }
  .source-block__link {
    color: inherit;
    text-decoration: underline;
    word-break: break-all; }
    .source-block__link:hover, .source-block__link:focus {
      text-decoration: none; }
  .source-block__note {
    padding-left: 24px;
    color: rgba(46, 40, 40, 0.7);
    font-size: 13px;
    font-weight: 400;
    line-height: 24px; }
    @media screen and (max-width: 640px) {
      .source-block__note {
        padding-left: 17px;
        font-size: 10px;
        line-height: 12.08px; } }
  .source-block_open .source-block__title::after {
    top: 13px;
    transform: rotate(45deg); }
    @media screen and (max-width: 640px) {
      .source-block_open .source-block__title::after {
        top: 5px; } }

.top-btn {
  width: 64px;
  height: 64px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #fff; }
  .top-btn::before {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 15px;
    height: 15px;
    border-left: 2px solid rgba(0, 0, 0, 0.5);
    border-top: 2px solid rgba(0, 0, 0, 0.5);
    transform: translate(-50%, -25%) rotate(45deg);
    content: ''; }

.content-wysiwyg .list-w-head {
  height: 100%;
  border: 1px solid #d3d3d3; }
  .content-wysiwyg .list-w-head__head {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 90px;
    padding: 5px 45px;
    background: #0874b5; }
    @media screen and (max-width: 1024px) {
      .content-wysiwyg .list-w-head__head {
        min-height: 110px;
        padding: 5px 20px; } }
  .content-wysiwyg .list-w-head__title {
    margin: 0 !important;
    font-weight: bold;
    color: #fff; }
  .content-wysiwyg .list-w-head__body {
    padding: 33px 45px 66px; }
    @media screen and (max-width: 1024px) {
      .content-wysiwyg .list-w-head__body {
        padding: 5px 25px 25px; } }
    @media screen and (max-width: 640px) {
      .content-wysiwyg .list-w-head__body {
        padding: 20px; } }
  .content-wysiwyg .list-w-head__list {
    margin: 0;
    padding-left: 0;
    padding-right: 0; }
  .content-wysiwyg .list-w-head__text {
    padding-left: 0;
    padding-right: 0; }
    .content-wysiwyg .list-w-head__text p:first-child {
      margin-top: 0; }
  .content-wysiwyg .list-w-head__list-item {
    position: relative;
    padding-left: 40px; }
    @media screen and (max-width: 640px) {
      .content-wysiwyg .list-w-head__list-item {
        padding-left: 30px; } }
    .content-wysiwyg .list-w-head__list-item:not(:first-child) {
      margin-top: 33px; }
    .content-wysiwyg .list-w-head__list-item::before {
      content: '';
      top: 9px;
      left: 0;
      display: block;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #0874b5; }
      @media screen and (max-width: 640px) {
        .content-wysiwyg .list-w-head__list-item::before {
          width: 7px;
          height: 7px; } }

.artic__table-block h3 {
  font-size: 16px;
  line-height: 1.5; }
  @media screen and (max-width: 1024px) {
    .artic__table-block h3 {
      font-size: 14px; } }

@media screen and (max-width: 640px) {
  .artic__table-wrap {
    overflow-x: auto; } }

.artic__table-wrap table {
  width: 100%;
  border: solid #ebf5f9;
  border-width: 0 2px; }

.artic__table-wrap + p {
  margin-top: 40px;
  font-size: 16px;
  line-height: 1.5; }

.artic__table-wrap thead {
  background: #ebf5f9; }

.artic__table-wrap th {
  padding: 14px 18px 17px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.3;
  color: #037bbb;
  text-align: left; }
  @media screen and (max-width: 640px) {
    .artic__table-wrap th {
      font-size: 12px; } }

.artic__table-wrap td {
  padding: 12px 18px 16px;
  font-size: 16px;
  border: solid #ebf5f9;
  border-width: 0 0 2px; }
  @media screen and (max-width: 640px) {
    .artic__table-wrap td {
      font-size: 12px; } }

* + .artic__table-wrap {
  margin-top: 30px; }

.artic-section_gray .artic__table-wrap table {
  border: solid #fff; }

.artic-section_gray .artic__table-wrap thead {
  background: #fff; }

.artic-section_gray .artic__table-wrap td {
  border-color: #fff; }

.artic__table-wrap.artic__table-wrap_bordered td {
  border-width: 0 2px 2px; }

@media screen and (max-width: 1024px) {
  .artic__table-wrap_empty table {
    border-width: 2px 2px 0; } }

@media screen and (max-width: 1024px) {
  .artic__table-wrap_empty thead {
    display: none; } }

.artic__table-wrap_empty th {
  padding: 14px 15px 16px; }

@media screen and (max-width: 1024px) {
  .artic__table-wrap_empty tr:not(:first-child) {
    display: none; } }

.artic__table-wrap_empty td {
  padding: 19px 18px 20px; }
  @media screen and (max-width: 1024px) {
    .artic__table-wrap_empty td {
      position: relative;
      display: block;
      padding: 0; } }

@media screen and (max-width: 1024px) {
  .artic__table-wrap_empty td:before {
    display: block;
    box-sizing: border-box;
    content: attr(data-label);
    width: 26%;
    padding: 12px 15px 30px;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.3;
    color: #037bbb;
    background: #ebf5f9; } }

@media screen and (max-width: 640px) {
  .artic__table-wrap_empty td:before {
    width: 50%;
    padding: 13px 4px 27px;
    font-size: 14px;
    line-height: 1.2; } }

.list-big-icon__list {
  list-style: none; }

.list-big-icon__item {
  display: flex;
  align-items: center; }
  .list-big-icon__item + .list-big-icon__item {
    margin-top: 50px; }
  @media screen and (max-width: 640px) {
    .list-big-icon__item {
      flex-direction: column; } }

.list-big-icon__img-col {
  flex: 0 0 19%; }
  @media screen and (max-width: 640px) {
    .list-big-icon__img-col {
      flex: 0 0 auto; } }

.list-big-icon__img-wrap {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  border: 1px solid #037bbb;
  border-radius: 50%; }
  @media screen and (max-width: 640px) {
    .list-big-icon__img-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100px;
      height: 100px;
      padding-bottom: 0; } }

.list-big-icon__img {
  max-width: 100px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }
  @media screen and (max-width: 640px) {
    .list-big-icon__img {
      position: static;
      transform: none; } }

.list-big-icon__text-col {
  flex: 1 1 auto;
  margin-left: 9%; }
  @media screen and (max-width: 640px) {
    .list-big-icon__text-col {
      margin-left: 0;
      margin-top: 30px; } }

.list-big-icon__title {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold; }
  @media screen and (max-width: 640px) {
    .list-big-icon__title {
      font-size: 14px; } }

.list-big-icon__text {
  font-size: 16px;
  line-height: 1.5; }
  @media screen and (max-width: 640px) {
    .list-big-icon__text {
      font-size: 14px; } }

.grid-list_3col__row {
  display: flex;
  justify-content: center;
  align-items: flex-start; }
  .grid-list_3col__row + .grid-list_3col__row {
    margin-top: 30px; }
  @media screen and (max-width: 640px) {
    .grid-list_3col__row {
      flex-direction: column;
      align-items: stretch; } }

.grid-list_3col__col {
  flex: 0 0 33.333%;
  max-width: 33.333%;
  padding: 0 30px; }
  @media screen and (max-width: 640px) {
    .grid-list_3col__col {
      flex: 0 0 auto;
      max-width: none; }
      .grid-list_3col__col + .grid-list_3col__col {
        margin-top: 40px; } }

.grid-list_3col__item {
  display: flex;
  flex-direction: column;
  align-items: center; }

.grid-list_3col__icon-wrap {
  max-width: 80px; }

.grid-list_3col__icon {
  display: block;
  max-width: 100%; }

.grid-list_3col__text {
  width: 100%;
  margin-top: 24px;
  text-align: center;
  line-height: 1.5; }
